import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Category } from '../models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private baseUrl = 'http://shop.pinok.co.tz/pinok_api';//online url
  // private baseUrl = 'http://localhost/pinok_api';
  constructor(private http:HttpClient) { }

  getCategories():Observable<any>{
    return this.http.post<Category>(`${this.baseUrl}/category/get_all`,{});
  }
  addCat(cat:Category):Observable<any>{
    return this.http.post(`${this.baseUrl}/category/add`,cat);    
  }
  getCategory(id:number):Observable<Category>{   
    return this.http.post<Category>(`${this.baseUrl}/category/get_one?id=${id}`,{});
  }
  updateCat(id:number,cat:Category):Observable<any>{
    return this.http.post(`${this.baseUrl}/category/update?id=${id}`,cat);    
  }
}
