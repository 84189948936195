import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Order } from '../models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
private baseUrl = 'http://shop.pinok.co.tz/pinok_api';//online url
// private baseUrl = 'http://localhost/pinok_api';
constructor(private http:HttpClient) { }

getOrders():Observable<any>{
  return this.http.post<any>(`${this.baseUrl}/orders/get_all`,{});
}
sortByDates(daterange:any):Observable<any>{
  return this.http.post(`${this.baseUrl}/orders/sort_by_date`,daterange);    
}
processOrder(orderId:number,saleId:number,status:string):Observable<any>{   
  return this.http.put<any>(`${this.baseUrl}/orders/process?orderId=${orderId}&salesId=${saleId}&status=${status}`,{});
}
// updateCat(id:number,cat:Category):Observable<any>{
//   return this.http.post(`${this.baseUrl}/category/update?id=${id}`,cat);    
// }
}
