import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './admin/layouts/admin-layout/admin-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './admin/components/components.module';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { DecimalPipe } from '@angular/common';
import { ToastService } from './services/toast.service';
import { ToastsContainer} from './toasts-container/toasts-container.component';
import { CategoryService } from './services/category.service';
import { UnitService } from './services/unit.service';
import { ProductService } from './services/product.service';
import { OrderService } from './services/order.service';
import { InvoiceService } from './services/invoice.service';
import { SalesService } from './services/sales.service';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './guard/auth-guard.service';
import { DashboardService } from './services/dashboard.service';
import { StaffComponentsModule } from './staff/components/components.module';
import { StaffLayoutComponent } from './staff/layouts/staff-layout/staff-layout.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    StaffComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule  
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    StaffLayoutComponent,
    ToastsContainer,
    LoginComponent,
    RegisterComponent
  ],
  providers: [
    UserService,
    AuthService, 
    CategoryService, 
    UnitService, 
    OrderService,
    InvoiceService,
    SalesService,
    DecimalPipe,
    ProductService,
    ToastService,
    AuthService,
    AuthGuard,
    DashboardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }