import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/staff/stock', title: 'Manage Stock',  icon:'fa fa-database', class: '' },
    { path: '/staff/customer', title: 'Customers',  icon:'fa fa-users', class: '' }, 
    { path: '/staff/orders', title: 'Customer Orders',  icon:'ni-delivery-fast', class: '' },
    { path: '/staff/invoices', title: 'Invoices',  icon:'fa fa-fax', class: '' }   
];

@Component({
  selector: 'app-staff-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router,private userService:UserService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
  logout(){
     this.userService.logOut()
         .subscribe(info=>{
           this.router.navigate(['login']);
         })
  }
}
