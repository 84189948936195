import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Invoice } from '../models/invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private baseUrl = 'http://shop.pinok.co.tz/pinok_api';//online url
  // private baseUrl = 'http://localhost/pinok_api';
  constructor(private http:HttpClient) { }

  getInvoices():Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/payments/get_all`,{});
  }
  confirmPayment(paymentId:number,userId:number):Observable<any>{   
    return this.http.put<any>(`${this.baseUrl}/payments/confirm?paymentId=${paymentId}&userId=${userId}`,{});
  }
}