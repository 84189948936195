import { Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient } from '@angular/common/http';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root'
})

export class UserService { 
  private baseUrl = 'http://shop.pinok.co.tz/pinok_api';//online url
  // private baseUrl = 'http://localhost/pinok_api';

  constructor(private http:HttpClient){}

  getUsers():Observable<User[]>{
     return this.http.get<User[]>(`${this.baseUrl}/users/getAll`);
  }
  getSalesPerson():Observable<any>{
    return this.http.post<User>(`${this.baseUrl}/users/get_sales_person`,{})               
  } 
  getCustomers():Observable<any>{
    return this.http.post<User>(`${this.baseUrl}/users/get_customers`,{})               
  }            
  getUser(id:number):Observable<User>{   
      return this.http.get<User>(`${this.baseUrl}/users/getOne?id=${id}`);
  }
  getUserByEmail(email:string):Observable<User>{   
    return this.http.get<User>(`${this.baseUrl}/users/getEmail?email=${email}`);
  }
  register(user:User):Observable<any>{
     return this.http.post(`${this.baseUrl}/users/add`,user);    
  }
  updateUser(id:number,user:User):Observable<any>{
    return this.http.post(`${this.baseUrl}/users/update?id=${id}`,user);    
 }
  logIn(user):Observable<any>{
    return this.http.post(`${this.baseUrl}/users/login`,user);    
  }
  logOut():Observable<any>{
    return this.http.get(`${this.baseUrl}/users/logout`);    
  }
  manageStaffAccount(id:number):Observable<any>{   
    return this.http.put<User>(`${this.baseUrl}/users/manage_account?id=${id}`,{});
  }
  manageCustomerAccount(id:number):Observable<any>{   
    return this.http.put<User>(`${this.baseUrl}/users/manage_customer_account?id=${id}`,{});
  }
  removeUser(id:number):Observable<any>{
    return this.http.get(`${this.baseUrl}/users/remove?id=${id}`);
  }
}