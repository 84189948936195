import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

interface Alert{
  type:string;
  header:string;
  msg:string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public userData:FormGroup
  public alert:Alert;
  public sendingIncomplete:boolean = false;

  constructor(
    private fb:FormBuilder,
    private userService:UserService,
    private router:Router
    ) { }
  
  ngOnInit(): void {
    this.alert = {type:"",header:"",msg:""};
    this.createForm();
  }
  
  createForm(){
    this.userData = this.fb.group({
      fullname:["",Validators.required],
      email:["",[Validators.required,Validators.email]],
      phone:["",Validators.required],
      pwd1:["",Validators.required],
      pwd:["",Validators.required]
    })
  }
  get pwd(){
    return this.userData.get("pwd");
  }
  get pwd1(){
    return this.userData.get("pwd1");
  }
  register(){
    if(this.userData.valid){
       if(this.pwd.value === this.pwd1.value){
        this.sendingIncomplete = true; 
        this.userData.value.title = "sales";
        this.userService.register(this.userData.value)
            .subscribe(info=>{
               this.sendingIncomplete = false;
                this.alert = {type:info.status,header:"Success",msg:" "+info.msg};
                setTimeout(()=>{
                   this.router.navigate(['login']);
                },2000)
            },err=>{
              this.sendingIncomplete = false;
              let error = err.error;
              this.alert = {type:error.status,header:"Warning",msg:" "+error.msg}
            })
       }else{
        this.alert = {type:"warning",header:"Warning",msg:" Passwords do not match !"}
       }
    }else{
      this.alert = {type:"warning",header:"Warning",msg:" Invalid Form data!"}
    }
  }
}