import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Category } from '../models/category';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  private baseUrl = 'http://shop.pinok.co.tz/pinok_api';//online url
  // private baseUrl = 'http://localhost/pinok_api';
  constructor(private http:HttpClient) { }

  getUnities():Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/unit/get_all`,{});
  }
  addUnit(unit:Category):Observable<any>{
    return this.http.post(`${this.baseUrl}/unit/add`,unit);    
  }
  getUnit(id:number):Observable<Category>{   
    return this.http.post<Category>(`${this.baseUrl}/unit/get_one?id=${id}`,{});
  }
  updateUnit(id:number,unit:Category):Observable<any>{
    return this.http.post(`${this.baseUrl}/unit/update?id=${id}`,unit);    
  }
}
