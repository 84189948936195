import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private baseUrl = 'http://shop.pinok.co.tz/pinok_api';//online url
  // private baseUrl = 'http://localhost/pinok_api';

  constructor(private http:HttpClient) { }

  getTopSummary():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/dashboard/get_top_summary`);
  }
  getSalesData():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/dashboard/get_sale_chart_data`);
  }
  getOrderData():Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/dashboard/get_order_chart_data`);
  }
}