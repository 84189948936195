import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private baseUrl = 'http://shop.pinok.co.tz/pinok_api';//online url
  // private baseUrl = 'http://localhost/pinok_api';

  constructor(private http:HttpClient) { }
  
  addProduct(product:Product):Observable<any>{
    return this.http.post(`${this.baseUrl}/products/add`,product);    
  }
  getProducts():Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/products/get_all`,{});
  } 
  getProduct(id:number):Observable<Product>{   
    return this.http.post<Product>(`${this.baseUrl}/products/get_one?id=${id}`,{});
  }
  updatePro(id:number,pro:Product):Observable<any>{
    return this.http.post(`${this.baseUrl}/products/update?id=${id}`,pro);    
  }
}