import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/admin/dashboard', title: 'Dashboard',  icon: 'ni-tv-2', class: '' },
    { path: '/admin/staff', title: 'Sales Person',  icon:'fa fa-user', class: '' },
    { path: '/admin/customer', title: 'Customers',  icon:'fa fa-users', class: '' },
    { path: '/admin/categories', title: 'Categories',  icon:'fa fa-cubes', class: '' },
    { path: '/admin/unities', title: 'Unities',  icon:'ni-app', class: '' },
    { path: '/admin/stock', title: 'Manage Stock',  icon:'fa fa-database', class: '' },
    { path: '/admin/orders', title: 'Customer Orders',  icon:'ni-delivery-fast', class: '' },
    { path: '/admin/invoices', title: 'Invoices',  icon:'fa fa-fax', class: '' },
    { path: '/admin/sales', title: 'Sales Report',  icon:'fa fa-file', class: '' }
   
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router,private userService:UserService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
  logout(){
     this.userService.logOut()
         .subscribe(info=>{
           this.router.navigate(['login']);
         })
  }
}
