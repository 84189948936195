import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { stringify } from 'querystring';

interface Alert{
  type:string;
  header:string;
  msg:string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public userData:FormGroup;
  public alert:Alert;
  public sendingIncomplete:boolean = false;
  private is_remberme:boolean = false;

  constructor(
    private fb:FormBuilder,
    private userService:UserService,
    private authService:AuthService,
    private router:Router
    ) { }

  ngOnInit(): void {
    this.alert = {type:"",header:"",msg:""};
    this.createForm();
  }
  
  createForm(){
    let auth = this.authService.getRemember();
    if(auth){
        this.is_remberme = !this.is_remberme;
        let authArr = auth.split(","),
            email = authArr[0],
            pwd = authArr[1];
        this.userData = this.fb.group({
          email:[email,[Validators.required,Validators.email]],
          pwd:[pwd,Validators.required],
          remberme:[true]
        })     
    }else{
      this.userData = this.fb.group({
        email:["",[Validators.required,Validators.email]],
        pwd:["",Validators.required],
        remberme:[false]
      })
    } 
  }
  get email(){
    return this.userData.get("email");
  }
  get pwd(){
    return this.userData.get("pwd");
  }
  setRembemberMe(){
    this.is_remberme = !this.is_remberme;
    if(!this.is_remberme){
      this.authService.clearRemember();
    }
  }
  login(){
    if(this.userData.valid){
       this.sendingIncomplete = true;
       this.userService.logIn(this.userData.value)
           .subscribe(info=>{
             this.sendingIncomplete = false;
             let user = JSON.parse(info.user);
             this.authService.setLoggedIn(true);
             this.authService.storeUser(info.user);
             if(this.is_remberme){
               let authdata = [];
                   authdata[0] = this.email.value;
                   authdata[1] = this.pwd.value;                                            
               this.authService.setRemember(authdata.join(","));
             }else{
               this.authService.clearRemember();
             } 
             //allocate the user to their specific dashboard
             if(user.title.trim() === "admin"){
                this.router.navigate(['admin','dashboard']);
             }else if(user.title.trim() === "sales"){
                this.router.navigate(['staff','stock']);
             }                 
           },err=>{
             this.sendingIncomplete = false;
             let error = err.error;
             this.alert = {type:"warning",header:"Warning",msg:" "+error.msg}
             this.clearAlert();
           })
    }else{
      this.alert = {type:"warning",header:"Warning",msg:" Invalid Form data !"}
      this.clearAlert();
    }
  }
  clearAlert(){
    setTimeout(()=>{
     this.alert.msg = "";
    },2000)
  }
}