import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Sale } from '../models/sale';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  private baseUrl = 'http://shop.pinok.co.tz/pinok_api';//online url
  // private baseUrl = 'http://localhost/pinok_api';
  constructor(private http:HttpClient) { }

  getSales():Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/sales/get_all`,{});
  }
  sortByDates(daterange:any):Observable<any>{
    return this.http.post(`${this.baseUrl}/sales/sort_by_date`,daterange);    
  }
  // updateCat(id:number,cat:Category):Observable<any>{
  //   return this.http.post(`${this.baseUrl}/category/update?id=${id}`,cat);    
  // }
}